import React from 'react'
import ContentLoader from 'react-content-loader'

export const TopRatesLoader = props => (
  <ContentLoader
    width={500}
    height={200}
    speed={2}
    primaryColor='#f3f3f3'
    secondaryColor='#ecebeb'
    ariaLabel={null}
    {...props}
  >
    <rect x='0' y='0' rx='30' ry='30' width='60' height='60' />
    <rect x='80' y='0' rx='4' ry='4' width='240' height='20' />
    <rect x='80' y='30' rx='4' ry='4' width='80' height='16' />
    <rect x='80' y='60' rx='4' ry='4' width='120' height='16' />
  </ContentLoader>
)
