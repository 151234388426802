import React, { Component, Fragment } from 'react'
import { fetchTopRates } from '../makeAndHandleRequest'
import moment from 'moment'
import 'moment/locale/vi'

import { TopRatesLoader } from '../Loaders'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import Parser from 'html-react-parser'

import VisibilitySensor from 'react-visibility-sensor'
import { enquireScreen } from 'enquire-js'

moment.locale('vi')

class LastUpdates extends Component {
  state = {
    isMobile: null,
    items: null,
    cSelected: [],
    shouldBeShown: false
  }

  componentDidMount () {
    enquireScreen(mobile => {
      this.setState({
        isMobile: mobile
      })
    })
  }

  loadData = () => {
    fetchTopRates().then(res => {
      this.setState({
        items: res.data
      })
    })
  }

  handleOnVisible = (isVisible) => {
    if (!this.state.shouldBeShown && isVisible) {
      this.loadData()
    }
    if (isVisible) {
      this.setState({
        shouldBeShown: true
      })
    }
  }

  render () {
    const { items, isMobile } = this.state

    return (
      <VisibilitySensor onChange={(isVisible) => this.handleOnVisible(isVisible)}>
        <div className='topRates'>
          <>
            {!items && !isMobile && (
              <div className='row'>
                <div className='col-12 col-lg-4 py-1 px-4'>
                  <TopRatesLoader />
                </div>
                <div className='col-12 col-lg-4 py-1 px-4'>
                  <TopRatesLoader />
                </div>
                <div className='col-12 col-lg-4 py-1 px-4'>
                  <TopRatesLoader />
                </div>
                <div className='col-12 col-lg-4 py-1 px-4'>
                  <TopRatesLoader />
                </div>
                <div className='col-12 col-lg-4 py-1 px-4'>
                  <TopRatesLoader />
                </div>
                <div className='col-12 col-lg-4 py-1 px-4'>
                  <TopRatesLoader />
                </div>
              </div>
            )}
            {!items && isMobile && (
              <div className='row'>
                <div className='col-12 px-4'>
                  <TopRatesLoader />
                </div>
                <div className='col-12 px-4'>
                  <TopRatesLoader />
                </div>
              </div>
            )}
            {items && (
              <div className='row' style={{ marginTop: 10 }}>
                {items.map(item => (
                  <div className='col-12 col-lg-4' key={item.id}>
                    <div className='card card-hover-overlay hover-translate-y-n3' style={{ padding: 20, borderRadius: 12, height: 250, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                      <div style={{ fontSize: 16, fontWeight: 'bold' }}>
                        <a href={item.novel.url} title={item.novel.name} className='text-dark text-decoration-none'>{item.novel.name}</a>
                      </div>
                      <div style={{ maxHeight: 76, paddingTop: 6, marginBottom: 20, overflow: 'hidden' }}>
                        {item.contentSafe && item.contentSafe.length > 0 && (
                          <div className='contentRate' style={{ paddingLeft: 10, fontSize: 12, fontStyle: 'italic', whiteSpace: 'pre-wrap', borderLeft: '4px solid #e8e8e8' }}>
                            {Parser(`${item.contentSafe}`)}
                          </div>
                        )}
                        {!item.contentSafe && (
                          <div className='contentRate' style={{ paddingLeft: 10, fontSize: 12, fontStyle: 'italic', whiteSpace: 'pre-wrap', borderLeft: '4px solid #e8e8e8' }}>
                            Thành viên đã đề cử {item.point} sao trong truyện {item.novel.name} của tác giả {item.novel.author}.
                          </div>
                        )}
                      </div>
                      {item.user && (
                        <div style={{ display: 'flex' }}>
                          <div style={{ width: 60, flexBasis: '30px', marginRight: 8 }}>
                            <LazyLoadImage
                              alt={item.user.name}
                              height={40}
                              width={40}
                              src={item.user.avatar}
                              style={{ borderRadius: 20, border: '2px solid #ffb400' }}
                            />
                          </div>
                          <div style={{ flex: 1, margin: '0 0.5rem' }}>
                            <div style={{ fontSize: 14, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                              <b><a href={item.user.url} title={item.user.name} className='text-dark text-decoration-none'>{item.user.name}</a></b>
                            </div>
                            <div style={{ color: '#ffb400', display: 'flex', fontSize: 14 }}>
                              {item.starRange.map((item, index) => (
                                <Fragment key={`star-${item.username}-${index}`}>
                                  {item === 1 && (
                                    <div><i className='fas fa-star' /></div>
                                  )}
                                  {item === 0.5 && (
                                    <div><i className='fas fa-star-half-alt' /></div>
                                  )}
                                  {item === 0 && (
                                    <div><i className='far fa-star' /></div>
                                  )}
                                </Fragment>
                              ))}
                            </div>
                            <div style={{ fontSize: 12 }}>Đã đề cử {item.point} sao</div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
            {items && items.length === 0 && (
              <div className='px-3 py-2'>
                <em>Không có truyện nào trong danh sách.</em>
              </div>
            )}
          </>
        </div>
      </VisibilitySensor>
    )
  }
}

export default LastUpdates
