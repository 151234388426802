import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import TopRates from './components/TopRates'

import './App.scss'
import { baseURL } from './makeAndHandleRequest'

const NovelTopRates = () => (
  <BrowserRouter basename={baseURL}>
    <Switch>
      <Route path='/' exact component={TopRates} />
    </Switch>
  </BrowserRouter>
)

export default NovelTopRates
