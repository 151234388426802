import axios from 'axios'
import { stringify } from 'qs'

// import fetch from 'isomorphic-fetch'
// import { getCookie } from './utils'

// Polyfill Promises for IE and older browsers.
require('es6-promise').polyfill()

/* eslint-disable */
export const baseURL                     = window.__REACT_DESKTOP_NOVEL_TOP_RATES__BASE_URL__ || '/'
export const novelTopRatesListURL        = window.__REACT_DESKTOP_NOVEL_TOP_RATES__LIST_URL__ || 'http://localhost:8000/novel-top-rates/api/list-rates/'
/* eslint-enable */

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'
axios.defaults.withCredentials = true

export function fetchTopRates (params) {
  let url = `${novelTopRatesListURL}?${stringify(params)}`
  return axios.get(url)
}
