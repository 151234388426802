export const initState = {
  issueType: 0,
  content: '',
  name: '',
  email: '',
  phoneNumber: ''
}

const reportIssue = (state = initState, action) => {
  switch (action.type) {
    case 'ADD_ISSUE':
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}

export default reportIssue
